/* eslint-disable no-tabs */
import { type ListingType } from '@/features/listing/types/listing-types';

interface ListingTypeSvgProps {
  type: ListingType;
}

export default function ListingTypeSvg(props: ListingTypeSvgProps) {
  const { type } = props;

  return (
    <>
      {type === 'haveshare' && (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="-4 -4 520 520"
        >
          <g stroke="currentcolor" strokeWidth="5" fill="currentcolor">
            <path
              d="M507.976,503.975h-10.444v-4.38c0-13.784-11.214-24.997-24.997-24.997H451.92v-332.51
					c0-24.726-20.115-44.841-44.839-44.841H373.17c-24.728,0-44.843,20.115-44.843,44.841v3.506
					c-33.08,2.088-59.373,29.589-59.373,63.185c0,2.209,1.789,4,4,4h31.525c1.941,13.664,13.685,24.218,27.875,24.218
					s25.934-10.554,27.875-24.218h31.501c2.211,0,4-1.791,4-4c0-33.607-26.309-61.114-59.404-63.186v-3.504
					c0-20.314,16.53-36.842,36.844-36.842h33.911c20.314,0,36.84,16.528,36.84,36.842v332.51H423.4
					c-13.784,0-24.998,11.214-24.998,24.997v4.38H359.58l-11.002-32.779c24.652-2.597,44.997-20.369,50.574-45.047l16.381-72.483
					c4.404-19.487-10.418-37.988-30.36-37.988h-32.688v-18.434c0-11.587-9.425-21.012-21.014-21.012H225.156
					c-7,0-13.17,3.474-16.99,8.753c-3.82-5.279-9.99-8.753-16.991-8.753H84.862c-11.587,0-21.012,9.425-21.012,21.012v18.434H31.162
					c-19.983,0-34.752,18.536-30.36,37.986l16.379,72.485c5.579,24.679,25.923,42.451,50.576,45.047l-11.002,32.779H4.024
					c-2.209,0-4,1.791-4,4s1.791,4,4,4c388.104,0,188.803,0,503.952,0c2.211,0,4-1.791,4-4S510.187,503.975,507.976,503.975z
					 M332.353,228.997c-9.765,0-17.932-6.966-19.791-16.192h39.582C350.285,222.031,342.117,228.997,332.353,228.997z
					 M387.586,204.779H277.097c2.05-28.617,25.94-51.275,55.045-51.38c0.065,0.003,0.119,0.037,0.184,0.037
					c0.068,0,0.124-0.035,0.19-0.038C361.632,153.489,385.535,176.154,387.586,204.779z M185.578,427.657H74.041
					c-10.263,0-18.611-8.351-18.611-18.613c0-10.263,8.349-18.613,18.611-18.613h111.538c10.148,0,18.565,8.216,18.565,18.613
					C204.143,419.436,195.73,427.657,185.578,427.657z M230.756,390.43h111.538c10.261,0,18.611,8.351,18.611,18.613
					c0,10.263-8.351,18.613-18.611,18.613H230.756c-10.148,0-18.565-8.216-18.565-18.613
					C212.191,398.651,220.604,390.43,230.756,390.43z M225.156,284.232H331.47c7.175,0,13.014,5.837,13.014,13.012v85.298
					c-0.725-0.06-1.452-0.111-2.191-0.111H230.756c-7.22,0-13.769,2.9-18.568,7.585v-92.772c0-0.073-0.021-0.14-0.021-0.212
					C212.282,289.957,218.054,284.232,225.156,284.232z M84.862,284.232h106.314c7.102,0,12.874,5.725,12.991,12.8
					c-0.001,0.072-0.021,0.14-0.021,0.212v92.77c-4.799-4.683-11.347-7.583-18.567-7.583H74.041c-0.74,0-1.467,0.052-2.191,0.111
					v-85.298C71.849,290.069,77.687,284.232,84.862,284.232z M24.985,424.388L8.606,351.903
					c-3.273-14.482,7.739-28.226,22.556-28.226H63.85v60.791c-9.629,4.008-16.42,13.511-16.42,24.576
					c0,14.674,11.938,26.613,26.611,26.613h111.538c9.534,0,17.888-5.056,22.589-12.61c4.7,7.555,13.055,12.61,22.589,12.61h111.538
					c14.674,0,26.611-11.938,26.611-26.613c0-11.065-6.79-20.568-16.42-24.576v-60.79h32.688c14.842,0,25.823,13.772,22.556,28.224
					v0.002l-16.381,72.485c-5.214,23.064-25.369,39.174-49.015,39.174h-47.027c-36.631,0-165.986,0-221.306,0
					C50.353,463.561,30.197,447.452,24.985,424.388z M293.56,471.561l30.046,32.415H92.727l30.046-32.415H293.56z M65.194,503.975
					l10.88-32.415h35.793l-30.046,32.415H65.194z M334.513,503.975l-30.048-32.415h35.797l10.878,32.415H334.513z M406.4,503.975
					v-4.38c0-9.372,7.624-16.998,16.998-16.998h49.136c9.374,0,16.998,7.626,16.998,16.998v4.38H406.4z"
            />
            <path
              d="M25.022,126.769h76.643c13.784,0,24.998-11.214,24.998-24.998V25.023c0-13.784-11.214-24.998-24.998-24.998H25.022
					c-13.784,0-24.998,11.214-24.998,24.998v76.748C0.024,115.555,11.238,126.769,25.022,126.769z M8.024,25.023
					c0-9.372,7.626-16.998,16.998-16.998h76.643c9.372,0,16.998,7.626,16.998,16.998v76.748c0,9.372-7.626,16.998-16.998,16.998
					H25.022c-9.372,0-16.998-7.626-16.998-16.998V25.023z"
            />
            <path
              d="M35.523,95.272h55.641c2.209,0,4-1.791,4-4v-55.75c0-2.209-1.791-4-4-4H35.523c-2.209,0-4,1.791-4,4v55.75
					C31.523,93.481,33.314,95.272,35.523,95.272z M39.523,39.521h47.642v47.751H39.523V39.521z"
            />
            <path
              d="M174.771,205.372h30.985c13.784,0,24.998-11.214,24.998-24.998v-31.048c0-13.784-11.214-24.998-24.998-24.998h-30.985
					c-13.784,0-24.998,11.214-24.998,24.998v31.048C149.773,194.158,160.987,205.372,174.771,205.372z M157.773,149.327
					c0-9.372,7.626-16.998,16.998-16.998h30.985c9.372,0,16.998,7.626,16.998,16.998v31.048c0,9.372-7.626,16.998-16.998,16.998
					h-30.985c-9.372,0-16.998-7.626-16.998-16.998V149.327z"
            />
          </g>
        </svg>
      )}

      {type === 'haveapartment' && (
        <svg viewBox="0 0 77 90" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none">
            <g
              id="Desktop-Copy-12"
              transform="translate(-416.000000, -502.000000)"
              stroke="currentcolor"
              strokeWidth="2.56"
            >
              <g id="Group" transform="translate(418.000000, 504.000000)">
                <path
                  d="M42.2347852,2.42045023 L71.1144296,32.8777823 C72.4702815,34.2897116 73.216,36.172284 73.216,38.1220912 L73.216,78.4629284 C73.216,82.6987163 69.7585778,86.1276874 65.4876444,86.1276874 L36.608,86.1276874 L7.72835556,86.1276874 C3.45742222,86.1276874 0,82.6987163 0,78.4629284 L0,38.1220912 C0,36.172284 0.745718519,34.2897116 2.10157037,32.8777823 L30.9812148,2.42045023 C34.0318815,-0.806816744 39.1841185,-0.806816744 42.2347852,2.42045023 Z"
                  id="Path"
                />
                <g transform="translate(12.202667, 39.559343)" id="Path">
                  <path
                    d="M17.7616593,19.3382797 L2.44053333,19.3382797 C1.28805926,19.3382797 0.406755556,18.4642282 0.406755556,17.3212378 L0.406755556,2.05895442 C0.406755556,0.915964031 1.28805926,0.0419125581 2.44053333,0.0419125581 L17.8294519,0.0419125581 C18.9819259,0.0419125581 19.8632296,0.915964031 19.8632296,2.05895442 L19.8632296,17.3212378 C19.795437,18.3969935 18.9141333,19.3382797 17.7616593,19.3382797 Z"
                  />
                  <path d="M10.1010963,0.0419125581 L10.1010963,19.3382797" />
                  <path d="M0.406755556,9.65647876 L19.795437,9.65647876" />
                </g>
                <path
                  d="M73.216,57.5529278 L59.4541037,57.5529278 C55.6577185,57.5529278 52.6070519,60.5784905 52.6070519,64.3436353 L52.6070519,86.1276874"
                  id="Path"
                />
                <path
                  d="M6.77925926,25.9500651 L6.77925926,5.10729922 C6.77925926,3.09025736 8.88082963,1.40938915 11.4569481,1.40938915 L16.3380148,1.40938915 C18.9141333,1.40938915 21.0157037,3.09025736 21.0157037,5.10729922 L21.0157037,11.6290679"
                  id="Path"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
              </g>
            </g>
          </g>
        </svg>
      )}

      {type === 'needapartment' && (
        <svg viewBox="0 0 81 94" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Desktop-Copy-12" transform="translate(-884.000000, -500.000000)" stroke="currentcolor">
              <g id="Group-33-Copy-2" transform="translate(886.000000, 502.000000)">
                <path
                  d="M44.3465244,2.54147274 L74.6701511,34.5216714 C76.0937956,36.0041972 76.8768,37.9808982 76.8768,40.0281957 L76.8768,82.3860748 C76.8768,86.8336521 73.2465067,90.4340718 68.7620267,90.4340718 L38.4384,90.4340718 L8.11477333,90.4340718 C3.63029333,90.4340718 4.97379915e-14,86.8336521 4.97379915e-14,82.3860748 L4.97379915e-14,40.0281957 C4.97379915e-14,37.9808982 0.783004444,36.0041972 2.20664889,34.5216714 L32.5302756,2.54147274 C35.7334756,-0.847157581 41.1433244,-0.847157581 44.3465244,2.54147274 Z"
                  id="Path"
                  strokeWidth="2.688"
                />
                <path
                  d="M7.11822222,27.2475684 L7.11822222,5.36266419 C7.11822222,3.24477023 9.32487111,1.4798586 12.0297956,1.4798586 L17.1549156,1.4798586 C19.85984,1.4798586 22.0664889,3.24477023 22.0664889,5.36266419 L22.0664889,12.2105213"
                  id="Path"
                  strokeWidth="2.688"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
                <g
                  id="avatar-copy-2"
                  transform="translate(18.900000, 31.500000)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.1"
                >
                  <g id="Group-3" transform="translate(0.168538, 0.122573)">
                    <path
                      d="M12.0621064,37.7156101 C11.7892028,39.1426487 11.3040408,40.5696872 10.5762978,41.8726355 C10.2730715,42.4310419 10.4853299,43.082516 11.0311372,43.3617192 C12.092429,43.889103 13.8814639,44.69569 16.0343703,45.2230738 C18.3692124,45.8125028 21.1588939,46.1227285 23.9485754,45.502277 C24.5247053,45.3781867 24.8885768,44.8197803 24.7976089,44.2303513 C24.5550279,42.9894483 24.4034148,40.662755 25.434384,37.6845876 C25.5556745,37.3433392 25.8589008,37.064136 26.2227723,37.0020909 C32.3782651,35.9473232 37.078272,30.4563271 37.078272,23.8485182 C37.078272,20.1258089 35.5924634,16.7753706 33.196976,14.324587 C29.8918099,11.0051712 24.46406,11.5325551 21.7653464,15.4103772 C20.1885699,17.6750253 17.6111468,20.0017186 13.547915,20.6221702 C12.577591,20.777283 11.607267,20.6221702 10.6672657,20.342967 C10.151781,20.1878541 9.51500592,20.2498992 8.84790817,20.9323959 C7.27113167,22.5765925 8.36274617,27.3540694 10.2124263,27.7883855 C10.3640394,27.819408 10.5156525,27.8504306 10.636943,27.8504306 C11.1524277,27.8504306 11.6982349,28.2227015 11.9104933,28.7190628 C11.9408159,28.7811079 12.5472684,30.1150788 12.2137195,31.9143882"
                      id="Path"
                    />
                    <path
                      d="M29.6626176,29.6626176 C29.6626176,29.6626176 27.0677746,32.2078555 21.5728128,30.3042742"
                      id="Path"
                    />
                    <path
                      d="M11.8867009,37.7524224 C4.89452391,34.8054379 0,27.7947169 0,19.6052021 C0,8.7789117 8.6034178,0 19.2132864,0 C29.823155,0 38.4265728,8.7789117 38.4265728,19.6052021 C38.4265728,22.7383119 37.4537482,25.7783591 36.1769158,28.4151347"
                      id="Path"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      )}

      {type === 'needroom' && (
        <svg width="79px" height="95px" viewBox="0 0 79 95" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <g
              id="Desktop-Copy-12"
              transform="translate(-650.000000, -500.000000)"
              stroke="currentcolor"
              strokeWidth="2.6752"
            >
              <g id="avatar" transform="translate(651.000000, 502.000000)">
                <g id="Group-3" transform="translate(0.334400, 0.243200)">
                  <path
                    d="M23.9327508,74.8325598 C23.3912754,77.6639855 22.4286524,80.4954112 20.9847178,83.0806259 C20.3830785,84.1885751 20.804226,85.4811825 21.8871769,86.0351571 C23.9929148,87.0815536 27.5425871,88.6819246 31.8142268,89.728321 C36.44685,90.897823 41.9819323,91.5133503 47.5170147,90.2822956 C48.6601295,90.0360847 49.3820968,88.9281355 49.2016049,87.7586336 C48.7202934,85.2965243 48.4194737,80.6800694 50.4650477,74.7710071 C50.7057034,74.093927 51.3073428,73.5399524 52.0293101,73.416847 C64.2425895,71.3240541 73.568,60.4292204 73.568,47.3184884 C73.568,39.9321606 70.619967,33.2844655 65.8670159,28.4217996 C59.3091466,21.8356572 48.5398016,22.8820537 43.1852111,30.5761452 C40.0566863,35.0694947 34.9427515,39.6859496 26.8807838,40.9170043 C24.9555378,41.2247679 23.0302917,40.9170043 21.1652097,40.3630297 C20.1424227,40.055266 18.87898,40.1783715 17.5553733,41.5325316 C14.4268486,44.7948264 16.5927503,54.2739472 20.2627506,55.1356854 C20.5635703,55.1972382 20.86439,55.2587909 21.1050457,55.2587909 C22.1278327,55.2587909 23.2107836,55.9974237 23.6319311,56.9822674 C23.6920951,57.1053729 24.8953738,59.7521404 24.2335705,63.3221989"
                    id="Path"
                  />
                  <path d="M58.8544,58.8544 C58.8544,58.8544 53.7059019,63.9044752 42.8032,60.1275282" id="Path" />
                  <path
                    d="M23.5847241,74.9056 C9.71135696,69.0584085 0,55.1482478 0,38.8992105 C0,17.4184756 17.0702734,0 38.1216,0 C59.1729266,0 76.2432,17.4184756 76.2432,38.8992105 C76.2432,45.1156983 74.3129924,51.1475379 71.7795949,56.3792355"
                    id="Path"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      )}
    </>
  );
}
