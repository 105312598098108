'use client';

import Link from 'next/link';
import { useTranslations } from 'next-intl';

import { type ListingType } from '@/features/listing/types/listing-types';
import ListingTypeSvg from '@/ui/svg/listing-type-svg';

import styles from './listing-icon-box.module.scss';

interface ListingIconBoxProps {
  listingType: ListingType,
  href: string,
}

export default function ListingIconBox(props: ListingIconBoxProps) {
  const {
    href,
    listingType,
  } = props;

  const translationsListingType = useTranslations('home.listing.service-type-landing');

  return (
    <Link
      className={styles.listingIconBox}
      href={href}
    >
      <div className={styles.listingIconBox_imageWrapper}>
        <ListingTypeSvg type={listingType} />
      </div>

      <p className={styles.listingIconBox_name}>
        {translationsListingType(listingType)}
      </p>
    </Link>
  );
}
